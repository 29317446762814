<template>
    <div class="additional-info">
        <h1 class="additional-info--header">{{ $t('generalTabs.additionalInfo') }}</h1>
        <div class="additional-info--container">
            <div>
                <div class="additional-info--item-content">
                    <div class="additional-info--item">
                        <p class="additional-info--item-header">{{ $t('generalTabs.m3uLink') }}</p>
                        <div class="additional-info--item-container">
                            <label class="additional-info--item-label">{{ $t('generalTabs.additionalInfoM3ULink') }}</label>
                            <input class="additional-info--item-input link" type="text" :value="clientObj.m3uLink" readonly>
                        </div>
                        <div @click="copyToClipboard(clientObj.m3uLink)" class="additional-info--item-copy">
                            <img class="additional-info--item-copy-img" src="@/components/assets/copy.svg" alt="">
                        </div>
                    </div>
                    <div class="qr-code--mobile-container">
                        <QrCode :link="clientObj.m3uLink" />
                    </div>
                </div>
                <div class="additional-info--item">
                    <p class="additional-info--item-header">{{ $t('global.myReferral') }}</p>
                    <div class="additional-info--item-container">
                        <label class="additional-info--item-label">{{ $t('generalTabs.additionalInfoReferral') }}</label>
                        <input class="additional-info--item-input" type="text" :value="clientObj.id" readonly>
                    </div>
                    <div @click="copyToClipboard(clientObj.id)" class="additional-info--item-copy">
                        <img class="additional-info--item-copy-img" src="@/components/assets/copy.svg" alt="">
                    </div>
                </div>
                <div class="additional-info--item">
                    <p class="additional-info--item-header">Surveys</p>
                    <div class="additional-info--item-container">
                        <p class="additional-info--item-label">{{ $t('generalTabs.additionalInfoSurveys') }}</p>
                        <label class="additional-switch">
                            <input class="additional-checkbox" v-model="clientObj.dontCallMe"
                                @change="(e) => onToggleDontCallMe(e.target.checked)" type="checkbox">
                            <span class="additional-slider round"></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="qr-code--container">
                <QrCode :link="clientObj.m3uLink" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        onToggleDontCallMe: Function,
        createdFunc: Function
    },
    computed: {
        ...mapGetters(['clientObj'])
    },
    methods: {
        copyToClipboard(value) {
			navigator.clipboard.writeText(value)
		},
    },
    components:{
        QrCode: () => import('@/components/QrCode.vue'),
    },
    async created() {
        await this.createdFunc()
    }
}
</script>

<style scoped>
h1,
p,
button,
input,
label,
a {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.additional-info {
    margin-top: 32px;
    height: fit-content;
    background-color: rgba(31, 33, 39, .6);
    padding: 24px 32px;
    border-radius: 12px;
    width: 92%;
}

.additional-info--header {
    font-size: 20px;
    font-weight: 600;
    color: white;
}

.additional-info--container{
    display: flex;
    align-items: flex-start;
}

.additional-info--item {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
}

.additional-info--item:nth-child(4) {
    border: none;
    padding-bottom: 0;
}

.additional-info--item-header {
    font-size: 14px;
    font-weight: 600;
    line-height: 17.64px;
}

.additional-info--item-label {
    font-size: 13px;
    margin-bottom: 12px;
}

.additional-info--item-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 70%;
}

.additional-info--item-content{
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.additional-info--item-copy {
    position: absolute;
    padding: 5px 8px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.12);
    right: 2%;
    bottom: 22px;
    cursor: pointer;
}

.additional-info--item-copy-img {
    width: 16px;
}

.additional-info--item-input {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    font-weight: 400;
    border-radius: 8px;
    padding: 12px 35px 12px 16px;
    outline: none;
    border: 1px solid rgba(255, 255, 255, 0.1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.link {
    color: #918FFA;
}

.switch {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 20px;
}

.additional-switch .additional-checkbox {
    opacity: 0;
    width: 0;
    height: 0;
}

.additional-slider {
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 32px;
    height: 20px;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.additional-slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: #1f2127;
    -webkit-transition: .4s;
    transition: .4s;
}

.additional-checkbox:checked+.additional-slider {
    background-color: #4E4BCD;
}

.additional-checkbox:focus+.additional-slider {
    box-shadow: 0 0 1px #4E4BCD;
}

.additional-checkbox:checked+.additional-slider:before {
    -webkit-transform: translateX(10px);
    -ms-transform: translateX(10px);
    transform: translateX(10px);
}

.additional-slider.round {
    border-radius: 34px;
}

.additional-slider.round:before {
    border-radius: 50%;
}

.qr-code--mobile-container{
    display: none;
}

@media only screen and (max-width: 1500px) {
    .additional-info {
        width: auto;
    }
}

@media only screen and (max-width: 720px) {
    .additional-info--item {
        flex-direction: column;
    }

    .additional-info--item-container {
        width: 100%;
    }

    .additional-info--item-header {
        margin-bottom: 12px;
    }
    
    .qr-code--container{
        display: none;    
    }

    .qr-code--mobile-container{
        display: block;
    }
}
</style>